.teamMember {
  display: flex;
  margin-bottom: 30px;
  text-align: start;
  /* font-family: "Montserrat Alternates"; */
}

.teamMember img {
  margin: 0px 70px;
  height: 400px;
  width: auto;
}
#ani img {
  margin-bottom: 5px;
}
.member {
  display: flex;
  justify-content: space-between;
}

.teamMember p {
  text-align: start;
  max-width: 80%; /* პოტექსტის სიგანე */
  font-size: 1rem; /* პოტექსტის ზომა */
  line-height: 1.1; /* ხაზებს შორის მანძილი */
}

.socialMediaLinks img {
  height: 32px;
  width: 32px;
  background-color: white;
  border-radius: 30%;
  margin: 0;
}

.socialMediaLinks img:hover {
  cursor: pointer;
  transform: scale(1.05);
  transition: transform 0.2s ease;
}

.socialMediaLinks a {
  display: inline-block;
  margin-right: 15px; /* დაშორება ლინკებს შორის */
}

.socialMediaLinks {
  margin: 20px; /* დაშორება ზევიდან */
  line-height: 40px;
}
.teamMemberInfo button {
  margin: 10px;
  background-color: rgba(255, 99, 146, 0.6);
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  /* width: 560px; */
  padding: 12px;
  width: 70%;
  border: 2px solid rgba(255, 99, 146, 1); /* შავი ფერი ბორდერისთვის */
  border-radius: 18px;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
}
.teamMemberInfo button:hover {
  cursor: pointer;
  background-color: rgba(244, 193, 199, 1);
  transform: scale(1.05);
  text-shadow: 0px 4px 4px rgba(255, 99, 146, 0.78);
}
.teamMemberInfo button:nth-of-type(2) {
  background-color: transparent;
  color: rgba(90, 169, 230, 1);
  border: 2px solid rgba(90, 169, 230, 1);
}
.teamMemberInfo button:nth-of-type(2):hover {
  box-shadow: 0px 4px 4px #5aa9e6;
  transform: scale(1.05);
  text-shadow: 0px 4px 4px #5aa9e6;
}

.teamMemberInfo h2,
.teamMemberInfo p {
  margin-top: 3%;
}

/* .teamMemberInfo h1 {
  font-family: "Montserrat Alternates";
} */
button {
  transition: transform 0.4s ease;
}

@media (max-width: 800px) {
  .teamMember {
    flex-direction: column;
    text-align: center;
    margin-bottom: 20px;
  }

  .teamMember img {
    margin: 0 auto;
    height: 250px;
    width: auto;
  }

  .teamMemberInfo {
    text-align: center;
    margin-top: 20px;
  }

  .teamMember p {
    max-width: 100%; /* პოტექსტის სრული სიგანე */
    font-size: 0.9rem; /* ტექსტის ზომის შემცირება */
    line-height: 1.3; /* ხაზებს შორის მეტი სივრცე */
  }

  .socialMediaLinks img {
    height: 28px;
    width: 28px;
    margin: 0 10px; /* ლინკებს შორის დაშორების შემცირება */
  }

  .socialMediaLinks {
    margin: 10px 0; /* დაშორება ზევიდან შემცირება */
    line-height: 30px; /* ლინკებს შორის დაშორების შემცირება */
  }

  .teamMemberInfo button {
    width: 100%; /* ღილაკები მთლიანი სიგანის */
    padding: 10px;
    font-size: 14px;
    margin: 10px 0;
  }
  #teo,
  #mari {
    flex-direction: column;
  }
}
