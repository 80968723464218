.footerLinkButton {
  background: none;
  border: none;
  font-size: 14px;
  cursor: pointer;
}
.ge .footerLinkButton {
  font-family: "FiraGO";
}

.footerContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto; /* სიმაღლე ავტომატურად */
  text-align: center;
  background-color: rgba(243, 217, 220, 1);
  color: #1b0a48;
  padding: 20px 0; /* დამატებითი padding სიმაღლისთვის */
  position: relative; /* მომავალში საჭიროებისთვის */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.ge .footerContainer {
  font-family: "firaGo";
}

.footerContainer.dark {
  background-color: rgba(2, 48, 71, 1);
  color: rgba(90, 169, 230, 1);
}

.footerContents {
  display: flex;
  justify-content: space-around; /* თანაბრად გაშლილი ელემენტები */
  align-items: flex-start; /* კონტენტების გასწორება ზემოთ */
  flex-wrap: wrap; /* მცირე ეკრანებზე კონტენტი რომ გადაეწყოს */
  padding: 0 5%; /* მარცხნიდან და მარჯვნიდან 5% padding */
}

/* Footer content blocks */
.footerContent1,
.footerContent2,
.footerContent3 {
  flex: 1 1 200px; /* ყველა კონტენტი მოქნილი */
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footerContent1 img {
  width: 270px;
  height: 100%;
}

.footerContent1 {
  gap: 12px;
}
.footerContent1 .socialIcons {
  display: flex;
  gap: 58px;
}

.socialIcons img {
  height: 32px;
  width: 32px;
  background-color: white;
  border-radius: 30%;
}

.socialIcons img:hover {
  cursor: pointer;
  transform: scale(1.05);
  transition: transform 0.2s ease;
}

.footerContent2 ul {
  list-style: none;
  text-align: center;
  line-height: 1.9;
}

.footerContent2 li a,
.footerLinkButton {
  text-decoration: none;
  color: #1b0a48;
}
.ge .footerUl li {
  font-family: "firaGo";
}

.dark .footerContent2 li a,
.dark .footerLinkButton {
  color: rgba(90, 169, 230, 1);
}

.dark .footerContent2 li a:hover,
.dark .footerLinkButton:hover,
.dark .footerContent3 ul li:hover {
  color: rgb(144, 192, 229);
}

.footerContent2 li a:hover,
.footerLinkButton:hover,
.footerContent3 ul li:hover {
  text-decoration: none;
  color: #006a9e;
}
.footerContent3 ul {
  text-align: start;
  line-height: 1.9;
  white-space: nowrap;
}
.footerContent3 ul li {
  display: flex;
  align-items: center;
  gap: 30px;
}

.footerContent3 ul li img {
  height: 24px;
  width: 24px;
}

/* Copyright section */
.copyRight {
  position: absolute;
  right: 5px;
  bottom: 5px;
  font-size: 12px;
  color: #1b0a48;
}

.dark .copyRight {
  color: rgba(90, 169, 230, 1);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .footerContainer {
    padding-top: 80px;
  }
  .footerContents {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
}
