.NavCont {
  display: flex;
  justify-content: center;
  width: 50vw;
  margin: 0 auto;
  padding: 0;
}

.UlCont {
  display: flex;
  gap: 5%;
  align-items: flex-start;
  list-style: none;
  width: 95%; /* მენიუს სრული სიგანე */
  padding: 0;
}

.UlCont li {
  white-space: nowrap; /* ერთი ხაზის შენარჩუნება */
  text-decoration: none;
}

.UlCont li a {
  text-decoration: none;
  position: relative;
  min-width: 120px; /* მინიმალური სიგანე */
  text-align: center; /* ტექსტის ცენტრში გასწორება */
  color: #1b0a48;
}

.UlCont li a::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -9px;
  height: 6px;
  background-color: rgba(255, 228, 94, 1);
  border-radius: 6px;
  width: 120%;
  margin-left: -10%;
  transform: scaleX(0);
  transform-origin: left;
  opacity: 0;
  transition: transform 0.4s ease, opacity 0.7s ease;
}

.UlCont li a:hover::after {
  opacity: 1;
  transform: scaleX(1);
}

/* Dark mode styles */
.dark .UlCont li a {
  color: rgba(90, 169, 230, 1);
}
.x {
  display: none;
}

.burger,
.UlCont li img,
.UlCont hr,
.mobileImageNavbar {
  display: none;
}
/* .UlCont li img {
  width: 2.1rem;
} */

/* Media queries for responsiveness */
@media (max-width: 800px) {
  .NavCont {
    width: 70vw; /* მცირე ეკრანებზე სიგანე 70%-ზე */
    justify-content: flex-end;
    font-size: 1.5rem;
  }
  .x {
    display: block;
    position: absolute;
    right: 13px;
  }

  .UlCont {
    flex-direction: column;
    position: fixed;
    left: 0;
    top: 9%;
    right: 5%;
    height: 100vh;
    background-color: #f3d9dc;
    padding: 20px;
    border-radius: 0px 8px 8px 0px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.9);
    opacity: 0;
    transform: scale(0.8) translateX(-100%);
    transition: transform 0.8s cubic-bezier(0.22, 1, 0.36, 1), opacity 0.8s ease;
    z-index: 2;
  }
  .dark .UlCont {
    border-radius: 0px 8px 8px 0px;
    background: #5ba9e6;
  }

  .UlCont.open {
    opacity: 1;
    transform: scale(1) translateX(0);
  }

  .UlCont li {
    opacity: 0;
    transform: scale(0.8) translateX(-120%);
    transition: opacity 0.5s ease, transform 1s ease;
  }

  .UlCont.open li {
    opacity: 1;
    transform: translateY(0);
  }

  .hamburger-menu {
    display: block;
    cursor: pointer;
    z-index: 3;
    position: absolute;
    top: 20vh;
    left: 1%;
  }

  .burger {
    display: flex;
    position: fixed;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 20px;
    top: 18px;
    margin-left: 20px;
  }

  .UlCont li img,
  .UlCont hr {
    display: block;
  }
  .UlCont li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 12%;
    cursor: pointer;
    width: 75%;
  }
  .UlCont li a {
    color: #1b0a48;
    text-align: end;
  }
  .dark .UlCont li a {
    color: #fff !important;
  }

  .UlCont hr {
    background: #0c022229;
    height: 1px;
    z-index: 999999;
    width: 90%;
    align-self: center;
  }
  .dark hr {
    background: rgba(255, 255, 255, 0.3);
  }
  .mobileImageNavbar {
    display: block;
    align-self: center;
  }
}
@media (max-width: 400px) {
  .NavCont {
    font-size: 1rem;
  }
}
