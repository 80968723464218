.dark .homeMainCont {
  background-color: rgba(3, 63, 93, 1);
}
.homeConts {
  padding-top: 30px;
  padding-bottom: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 90px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.homeCont1,
.homeCont2,
.homeCont3,
.homeCont4,
.homeCont5 {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 90%;
}
.homeCont3,
.homeCont5 {
  flex-direction: row-reverse;
}

.homeCont1 {
  background-color: rgba(243, 217, 220, 1);
}
.dark .homeCont1 {
  background-color: rgba(90, 169, 230, 0.6);
}

/* .homeCont1 img, */
.homeCont2 img,
.homeCont3 img,
.homeCont4 img,
.homeCont5 img {
  height: auto;
  width: 45%;
}
.dsNone {
  display: none;
}
.dsBlock {
  display: block;
}

.homeCont1 {
  align-items: center;
}
.homeCont1 img {
  width: 30%;
  max-height: 100%;
  align-self: center;
}
@-moz-document url-prefix() {
  /* სტილები, რომლებიც უნდა მოქმედებდეს მხოლოდ Firefox-ში */
  .homeCont1 img {
    width: 40vw;
  }
  .homeCont1Texts h2 {
    margin-bottom: 3%;
  }
  @media screen and (max-width: 800px) {
    .homeCont1Texts h2 {
      margin-bottom: 0%;
    }
  }
}

.homeCont1Texts {
  position: relative;
  top: 15%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* height: 50%;
  width: 50%; */
  align-self: flex-start;
  margin-top: 5%;
}

.homeCont1Texts h2 {
  max-width: 85%;
  align-items: center;
  text-align: center;
  color: rgba(255, 99, 146, 1);
  text-align: center;
  font-style: normal;
  /* font-weight: 400; */
  line-height: normal;
  font-family: "BPG Irubaqidze", sans-serif;
  white-space: nowrap;
}
.light .homeCont1Texts h2 {
  color: #1b0a48;
}
.en .homeCont1Texts h2 {
  font-family: "Nanum Brush Script";
  font-size: 5vw;
}
.ge .homeCont1Texts h2 {
  font-size: 4vw;
}

.homeCont1Texts p {
  text-align: center;
  max-width: 70%;
  font-size: 1.3vw;
  line-height: 1.1;
  color: rgba(255, 255, 255, 1);
  margin-bottom: 30px;
}
.light .homeCont1Texts p {
  color: #1b0a48;
}

.homeContTexts {
  padding-top: 55px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50%;
  width: 50%;
  gap: 8px;
}
.homeContTexts h1 {
  max-width: 85%; /* გაფართოვებული სიგანე h2-სთვის */
  text-align: center; /* ცენტრალური გასწორება */
  margin: 0 auto;
  font-size: 1.8rem; /* შესაძლო ზომა */
  color: rgba(255, 99, 146, 1);
}
.homeContTexts p {
  text-align: center;
  max-width: 65%; /* პოტექსტის სიგანე */
  margin: 10px auto; /* ზღვარი h2-ის ქვემოთ */
  font-size: 1rem; /* პოტექსტის ზომა */
  line-height: 1.2; /* ხაზებს შორის მანძილი */
  color: #1b0a48;
  /* word-spacing: 3px;  */
  letter-spacing: 1.1px; /* ასოებს შორის 1px მანძილი */
}
.homeContTexts p,
h5 {
  color: rgba(90, 169, 230, 1);
}
.homeCont2,
.homeCont3,
.homeCont4,
.homeCont5 {
  align-items: center;
  justify-content: space-between;
}

.homeContTexts button {
  background-color: rgba(255, 99, 146, 0.6);
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  width: 200px;
  padding: 12px;
  border: 2px solid rgba(255, 99, 146, 1);
  border-radius: 18px;
}

.priceButton {
  background-color: rgba(255, 99, 146, 0.6);
  border: 2px solid white;
}
.homeContTexts button:hover,
.priceButton:hover {
  cursor: pointer;
  background-color: rgba(244, 193, 199, 1);
}
.homeCont1 {
  background-color: rgba(243, 217, 220, 1);
  width: 100%;
}

@media screen and (max-width: 1100px) {
  .homeCont1 img {
    height: auto;
    align-self: center;
  }
}

@media (max-width: 800px) {
  .homeConts {
    padding-top: 20px;
    padding-bottom: 100px;
    gap: 60px;
  }

  .homeCont1,
  .homeCont2,
  .homeCont3,
  .homeCont4,
  .homeCont5 {
    flex-direction: column;
    height: auto; /* Allow height to adjust based on content */
  }

  .dsNone {
    display: block;
  }
  .dsBlock,
  .homeCont1Texts br {
    display: none;
  }
  .mobButton {
    margin-top: 50px;
  }
  .homeCont1 img,
  .homeCont2 img,
  .homeCont3 img,
  .homeCont4 img,
  .homeCont5 img {
    height: auto;
    width: 70%;
    margin-top: 3%;
  }
  .homeCont1 img,
  .homeCont1 {
    align-self: center;
    align-items: center;
    margin-top: 0%;
  }

  .homeCont1Texts,
  .homeContTexts {
    width: 90%;
    padding-top: 30px;
  }
  .homeCont1Texts {
    align-self: center;
    margin-top: 1%;
    margin-bottom: 40px;
  }

  .homeCont1Texts h2,
  .homeContTexts h1 {
    font-size: 1.5rem;
    max-width: 100%;
  }
  .homeCont1Texts h2,
  .homeCont1Texts p,
  .homeCont1Texts img {
    text-align: center;
    align-self: center;
  }

  .homeCont1Texts p,
  .homeContTexts p {
    max-width: 90%;
    font-size: 1rem;
    line-height: 1.3;
    margin-bottom: 3%;
  }

  .homeContTexts button {
    font-size: 14px;
    width: 75%;
  }

  .homeCont3,
  .homeCont5 {
    display: flex;
    flex-direction: column-reverse;
  }
}
@media screen and (max-width: 510px) {
  .en .homeCont1Texts h2 {
    font-family: "Nanum Brush Script";
    font-size: 32px;
  }
  .ge .homeCont1Texts h2 {
    font-size: 25px;
  }

  .homeCont1Texts p {
    font-size: 1rem;
  }
}
@media screen and (max-width: 400px) {
  .homeCont1Texts h2 {
    white-space: wrap;
  }
}
