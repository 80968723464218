.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: rgba(243, 217, 220, 1);
  /* color: #033f5d; */
  color: #1b0a48;
  font-size: 20px;
  padding: 4px 6%;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.en .header {
  font-family: Montserrat;
}
.ge .header {
  font-family: "BPGNinoMtavruliNormal";
}
.dark .header {
  background-color: rgba(2, 48, 71, 1);
  color: rgba(90, 169, 230, 1);
}
.headerLogoCont {
  display: flex;
}
.logo {
  height: 100%;
  width: 18vw;
  margin: 0px;
}

.headerLogoCont:hover {
  cursor: pointer;
  transform: scale(1.05);
  transition: transform 0.2s ease;
}
.lang {
  max-width: 50px;
  height: 30px;
  cursor: pointer;
}
.toggles {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  margin-left: 100px;
}
.ToggleGR {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0px;
  margin: -2px;
}
.ToggleGR p {
  display: block;
}
@media screen and (max-width: 1300px) {
  .header {
    font-size: 14px;
  }
}

@media screen and (max-width: 900px) {
  .header {
    font-size: 12px;
  }
}

@media screen and (max-width: 800px) {
  .header {
    font-size: 14px;
  }

  .headerLogoCont {
    transform: translateX(30vw);
    cursor: pointer;
  }
  .ToggleGR p {
    .headerLogoCont:hover {
      transform: translateX(110%);
      transition: transform 0.2s ease;
    }
    display: none;
  }
  .ToggleGR {
    max-width: 80px;
  }
  .lang {
    max-width: 70px;
  }
  .logo {
    width: 30vw;
  }
  .headerLogoCont:hover {
    cursor: pointer;
    transform: translateX(29vw);
    transition: transform 0.2s ease;
  }
}
