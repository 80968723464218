.dark .aboutMainCont {
  background-color: rgba(3, 63, 93, 1);
}

.aboutMainCont p,
.aboutMainCont h1,
.aboutMainCont h2 {
  color: #1b0a48;
}

.dark .aboutMainCont p,
.dark .aboutMainCont h1,
.dark .aboutMainCont h2 {
  color: #5aa9e6;
}
.colorPink {
  color: rgba(255, 99, 146, 1) !important;
}
.aboutConts {
  padding-top: 30px;
  padding-bottom: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 90px;
  /* font-family: "Montserrat Alternates"; */
}
.aboutCont1 {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: auto;
}
.aboutCont1 img {
  height: auto;
  width: 100%;
}
.aboutCont2,
.aboutCont3,
.members {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  height: auto;
  gap: 40px;
  text-align: center;
}
.aboutCont2 h1,
.aboutCont3 h1,
#aboutUsMainH1,
.serviceH2 {
  color: rgba(255, 99, 146, 1);
  text-align: center;
  /* font-family: "3D Unicode", sans-serif;
  font-style: italic; */
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.en .aboutCont2 h1,
.en .aboutCont3 h1,
.en #aboutUsMainH1,
.ge .serviceH2 {
  font-family: "Nanum Brush Script";
  font-size: 96px;
}
.ge .aboutCont2 h1,
.ge .aboutCont3 h1,
.ge #aboutUsMainH1,
.ge .serviceH2 {
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-family: "BPG Irubaqidze", sans-serif;
}

.aboutCont3 p {
  text-align: start;
}
/* .member {
    display: flex;
}
.member img {
    width: 50%;
    height: auto;
} */
#teo,
#mari {
  display: flex;
  flex-direction: row-reverse;
}
.socialMediaLinks h2 {
  margin-bottom: 6%;
}

@media screen and (max-width: 800px) {
  .aboutCont1 img {
    height: auto;
  }
  /* #ani img {
    margin-bottom: -100px;
  } */
  .aboutCont1 {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .aboutConts {
    gap: 30px;
  }

  .ge .aboutCont2 h1,
  .ge .aboutCont3 h1,
  .ge #aboutUsMainH1,
  .ge .serviceH2 {
    font-size: 2.5rem;
  }
  .en .aboutCont2 h1,
  .en .aboutCont3 h1,
  .en #aboutUsMainH1,
  .en .serviceH2 {
    font-size: 2.8rem;
  }
  .teamMember {
    gap: 20px;
  }
}
@media screen and (max-width: 420px) {
  .aboutCont2 h1,
  .aboutCont3 h1,
  #aboutUsMainH1,
  .ge .serviceH2 {
    font-size: 2rem;
    margin-top: 7%;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-family: "BPG Irubaqidze", sans-serif;
  }
}
