.prices {
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: white; */
  padding: 2% 10%;
  min-height: 100vh;
}
.priceSect1 img {
  width: 100%;
}

@media screen and (max-width: 800px) {
  .prices {
    padding: 2% 0%;
  }
}
