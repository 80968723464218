.calculation-page {
  padding: 2% 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20%;
}
.calculation-page h1 {
  color: #ff6392;
  margin-top: 10%;
  margin-bottom: 20% !important;
  text-align: center;
  font-size: 6rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.dark .calculation-page h1 {
  color: #ff6392;
}

.en .calculation-page h1 {
  font-family: "Nanum Brush Script";
}
.ge .calculation-page h1 {
  font-family: "BPG Irubaqidze", sans-serif;
}

.calculation-page textarea {
  width: 100%;
  height: auto;
}
.CalcSection2 {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.calculation-page .category h2,
.calculation-page .category h3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 0px 0px 0px 0px;
}
.light .calculation-page .category h2,
.light .calculation-page .category h3 {
  background: rgba(255, 99, 146, 0.5);
  color: #3c2d66c9;
  border: 2px solid #ff6392;
}

.dark .calculation-page .category h2,
.dark .calculation-page .category h3 {
  background: rgb(90, 169, 230);
  color: #3c2d66c9;
  border: 2px solid #1b0a48;
}

.calculation-page .category h3 {
  border-radius: 0px 0px 0px 0px;
  border: none;
  margin-bottom: 0.1px;
}
.categories {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  gap: 1px;
}
.category {
  width: 100%;
}
.calculation-page .checkbox-label {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #ff6392;
  color: #1b0a48;
  border-radius: 5px;
  margin-bottom: 10px;
}
.light .CalcSection2 textarea::placeholder,
.light .CalcSection2 input::placeholder {
  color: #b6b0c9;
}
.dark .CalcSection2 textarea::placeholder,
.dark .CalcSection2 input::placeholder {
  color: #5e9bba;
}
.calcForm {
  gap: 0.5rem;
  width: 100%;
  /* border: 3px solid #1b0a48; */
}
.CalcSection2 textarea,
.CalcSection2 input {
  color: #1b0a48;
  border: 2px solid rgba(255, 99, 146, 1);
  padding: 10px 7px;
  border-radius:5%;
  font-weight: bold;
}
.dark .CalcSection2 textarea,
.dark .CalcSection2 input {
  color: #1b0a48;
  border: 2px solid #1b0a48;
  font-weight: bold;
}

.CalcSection2 label {
  font-size: 15px;
  color: #1b0a48;
}

.calculation-page .checkbox-label input {
  display: none;
}

.calculation-page .checkbox-label .custom-checkbox {
  width: 24px;
  height: 24px;
  background-color: #fff;
  border: 2px solid #156eb3;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.calculation-page .checkbox-label .custom-checkbox svg {
  display: none;
}

.calculation-page .checkbox-label input:checked + .custom-checkbox svg {
  display: block;
}
.column {
  display: flex;
  flex-direction: column;
}

.submit2-button {
  background-color: #156eb3;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;
}

.submit2-button:hover {
  background-color: #104a85;
}
.calculation-page input[type="checkbox"] {
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #156eb3;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
}

.calculation-page input[type="checkbox"]:checked {
  background-color: #156eb3;
}

.calculation-page input[type="checkbox"]:checked::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #0d47a1;
  transform: translate(-50%, -50%);
}
.light .calculation-page input[type="checkbox"]:checked::after {
  background: #0d47a1;
}
.dark .calculation-page input[type="checkbox"]:checked::after {
  background: #fff;
}
.sub-category-content.expand label {
  flex-direction: row-reverse;
  display: flex;
  justify-content: space-between;
  padding: 10px 22px;
  border-bottom: 1px solid pink;
  align-items: center;
}
.dark .sub-category-content.expand label {
  color: #fff;
  border-bottom: 1px solid #1b0a48;
}

.icon {
  width: 24px;
  height: 24px;
  transition: transform 0.6s ease; /* Smooth transition for rotation */
  transform-origin: center;
}

.icon.rotate {
  transform: rotate(180deg); /* Rotate the icon */
}
.sub-category-content {
  max-height: 0;
  opacity: 1;
  overflow: hidden;
  transition: max-height 0.9s ease, opacity 0.5s ease; /* Smooth transition for height */
}

.sub-category-content.expand {
  max-height: 500px;
  opacity: 1;
  flex-direction: column;
  display: flex;
}
.light .sub-category-content.expand {
  border: 1px solid pink;
}
.dark .sub-category-content.expand {
  color: #fff;
  border: 1px solid #1b0a48;
}

@media screen and (max-width: 800px) {
  .categories {
    flex-wrap: wrap;

    gap: 1vw;
  }
  .calcForm {
    gap: 0.5rem;
    width: 90%;
  }
  .categories {
    display: flex;
    flex-wrap: wrap;
    width: 90%;
  }
  /* .sub-category-content.expand label {
    font-size: 1em;
  } */
}
