.readMoreBtn {
  border: none;
  background-color: transparent;
  color: rgb(114, 92, 122);
}

.dark .readMoreBtn {
  color: #688fad;
}
.ge .readMoreBtn {
  font-family: "firaGo";
}
.en .readMoreBtn {
  font-family: "Montserrat";
}
