@import url("https://fonts.googleapis.com/css2?family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nanum+Brush+Script&display=swap");
@import url("//cdn.web-fonts.ge/fonts/bpg-irubaqidze/css/bpg-irubaqidze.min.css");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "BPGNinoMtavruliNormal";
  src: url("./bpg_nino_mtavruli_normal.ttf");
  font-weight: normal;
  font-style: normal;
}
/* firago-latin-400-normal */
@font-face {
  font-family: "FiraGO";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(@fontsource/firago/files/firago-latin-400-normal.woff2)
      format("woff2"),
    url(@fontsource/firago/files/firago-latin-400-normal.woff) format("woff");
}

body.ge {
  /* font-family: "BPGNinoMtavruliNormal", sans-serif; */
  font-family: "FiraGo";
}
body.en {
  font-family: "Montserrat Alternates" !important;
}
.pageService.ge,
.contact.ge,
.prices.ge,
.homeMainCont.ge,
.aboutMainCont.ge,
.NavCont.ge,
footer.ge,
.header.ge,
button.ge {
  /* font-family: "BPGNinoMtavruliNormal", sans-serif !important; */
  font-family: "FiraGo" !important;
}
.pageService.en,
.contact.en,
.prices.en,
.homeMainCont.en,
.aboutMainCont.en,
.NavCont.en,
footer.en,
.header.en,
button.en {
  font-family: "Montserrat Alternates" !important;
}
.ge .priceButton,
.ge .submit-button,
.ge .portfBtn,
.ge .contactBtn,
.ge .mobButton {
  font-family: "FiraGO";
}
.en .priceButton,
.en .submit-button,
.en .portfBtn,
.en .contactBtn,
.en .mobButton {
  font-family: "Montserrat Alternates";
}
