.circle {
    position: absolute;
    border-radius: 50%;
    pointer-events: none;
    animation: blow 2s forwards; 
    z-index: -2;
  }
  
  @keyframes blow {
    0% {
      transform: translate(-50%, -50%);
      opacity: 1;
    }
    100% {
      transform: translate(-50%, -300%); 
      opacity: 0;
    }
  }
  