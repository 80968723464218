.contact {
  padding: 3% 10%;
}

.disNone {
  display: none;
}
.contactSect1 {
  position: relative;
}
.contactSect1 img {
  width: 100%;
}
.sect2Contact {
  display: flex;
  flex-direction: column;
  gap: 66px;
}

.border {
  border: 6px solid #ff6392;
  height: 100%;
  position: absolute;
  width: 90%;
  left: 6%;
  top: 0;
}
.dark .border {
  border: 6px solid #5aa9e6;
}
.contact p {
  margin-left: 6%;
}

.contactTextLast p,
.alignCenter {
  text-align: center;
  /* font-family: "Montserrat Alternates"; */
  font-size: 1.15rem;
}
.contact h1 {
  color: #ff6392;
  text-align: center;
  font-size: 6rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: -20px;
}

.en .contact h1 {
  font-family: "Nanum Brush Script";
}
.ge .contact h1 {
  font-family: "BPG Irubaqidze", sans-serif;
}

.contact-form-container {
  background-color: #1b0a48;
  width: 100%;
  max-width: 1440px;
  padding: 2rem;
  margin: 0 auto;
  border-radius: 8px;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 60%;
  padding: 2% 6%;
  align-self: center;
  border-radius: 12px;
  border: 3px solid #ff6392;
  background: rgba(255, 99, 146, 0.33);
}
.dark .contact-form {
  background: rgb(90, 169, 230);
  border: 2px solid #1b0a48;
}

label {
  color: #ff6392;
  font-size: 1.2rem;
}
.dark .contact label {
  color: #1b0a48;
  font-size: 1.2rem;
}
/* .dark label {
  color: #fff;
  font-size: 1.2rem;
} */

.contact input,
.contact textarea {
  width: 100%;
  padding: 1rem;
  border-radius: 12px;
  border: 2px solid #ff6392;
  background: #fff;
  color: #1b0a48;
}
.dark input,
.dark textarea {
  background: rgba(255, 255, 255, 0.5);
  border: 2px solid #033f5d;
  color: #1b0a48;
}

.light .contact input::placeholder,
.light .contact textarea::placeholder {
  color: #c4c4c4;
}
.dark .contact input::placeholder,
.dark .contact textarea::placeholder {
  color: #adbec6;
}

textarea {
  height: 150px;
}
.error-message {
  color: red;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

.submit-button {
  border-radius: 18px;
  border: 2px solid #ff6392;
  background: rgba(255, 99, 146, 0.6);
  box-shadow: 0px 4px 6px 0px rgba(255, 99, 146, 0.35);
  color: #fff;
  padding: 1rem;
  cursor: pointer;
  font-size: 1rem;
  width: 60%;
  align-self: center;
}
.dark .submit-button {
  border-radius: 18px;
  border: 2px solid #033f5d;
  background: #033f5d60;
  box-shadow: 0px 4px 6px 0px rgba(255, 99, 146, 0.35);
  color: rgb(233, 230, 251);
  padding: 1rem;
  cursor: pointer;
  font-size: 1rem;
  width: 60%;
  align-self: center;
}

.submit-button:hover {
  background: #f4c1c7;
  box-shadow: 0px 4px 6px 0px rgba(255, 99, 146, 0.35);
}
.dark .submit-button:hover {
  background: #033f5d3b;
  box-shadow: 0px 4px 6px 0px rgba(255, 99, 146, 0.35);
}
.contact-details {
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: #1b0a48;
  /* font-family: "Montserrat Alternates"; */
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.dark .contact-details {
  color: #5aa9e6;
}
.contact-details h3 {
  margin-bottom: 15px;
  margin-top: 15px;
}

.socNetworks {
  display: flex;
  /* justify-content: space-between; */
  align-self: center;
  margin-left: 15%;
  /* max-width: 90%; */
  /* gap: 90px; */
}
.socNetworks img {
  background: white;
  border-radius: 12px;
  max-width: 25%;
}
.socNetworks img:hover {
  cursor: pointer;
  transform: scale(1.05);
  transition: transform 0.2s ease;
}
.contactLogo {
  align-self: center;
  max-width: 100%;
}
.contactLogo img {
  width: 100%;
}

.contactTextLast h1 {
  padding-bottom: 50px;
}
.contactTextLast {
  padding-bottom: 120px;
}

.submit-overlay {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 8px;
  min-width: 100%;
  height: 100vh;
  align-items: center;
  align-content: center;
}

.submit-overlay img {
  width: 100vw;
}

.contact {
  position: relative;
  z-index: 1;
}
.contact a,
.footerContainer a {
  text-decoration: none;
}
.dark .contact a,
.dark .footerContainer a {
  color: #5aa9e6;
}
.light .contact a,
.light .footerContainer a {
  color: #1b0a48;
}

@media screen and (max-width: 800px) {
  .contact {
    font-size: 20px;
  }
  .contact-form {
    width: 85%;
    font-size: 18px;
  }
  .socNetworks {
    gap: 25px;
  }
  .contact h1,
  .calculation-page h1 {
    font-size: 4rem;
  }
  .contact-details {
    font-size: 20px;
  }
  .socNetworks,
  .contactLogo {
    display: none;
  }
  .contactTextLast {
    padding-top: 80px;
  }
}

@media screen and (max-width: 600px) {
  .contact-form {
    width: 100%;
  }
  .en .contact h1,
  .en .calculation-page h1 {
    font-size: 3rem;
  }
  .ge .contact h1,
  .ge .calculation-page h1 {
    font-size: 2rem;
  }

  .sect2Contact {
    padding: 1% 5%;
  }

  .contact-details {
    font-size: 16px;
  }

  .contactTextLast h1 {
    padding-bottom: 15px;
  }
}
