.pageService,
.contact {
  /* background-color: #fff; */
  padding: 2% 5%;
  display: flex;
  flex-direction: column;
  gap: 5vh;
  color: #1b0a48;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.serviceH2 {
  color: rgba(255, 99, 146, 1);
  margin-top: 7%;
}

.dark .pageService,
.dark .contact,
.dark .prices,
.dark .calculation-page {
  background-color: rgba(3, 63, 93, 1);
  color: #5aa9e6;
}

.pageService .image {
  width: 100%;
  height: 55vh;
}
.serviceCardsGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 70px;
  margin: 0 auto;
  justify-content: space-between;
  margin-bottom: 80px;
  margin-top: 60px;
}

.serviceCard {
  border-radius: 12px;
  padding: 40px;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  line-height: 1.5;
}

.light .serviceCard {
  background-color: transparent;
  box-shadow: 0px 4px 4px rgb(245 225 227);
  color: #6f648d;
}
.dark .serviceCard {
  background-color: #033f5d;
  /* background: #a0d468; */
  color: #e0e0e0;
  box-shadow: 0px 4px 4px #add8e6;
}
.serviceCard h2,
.serviceCard h3 {
  color: #1b0a48;
}
.dark .serviceCard h2,
.dark .serviceCard h3 {
  color: #add8e6;
}

.serviceCard:hover {
  cursor: pointer;
  transform: scale(1.07);
  transition: transform 1.8s ease;
}

/* .GD {
  background-color: rgba(255, 99, 146, 1);
}

.UX {
  background-color: rgba(90, 169, 230, 1);
}

.FR {
  background-color: rgba(123, 97, 255, 1);
}

.BK {
  background-color: rgba(255, 222, 61, 1);
} */

.serviceCard h2 {
  margin-bottom: 30px;
  font-size: 24px;
  text-align: center;
}

.serviceCard p {
  margin: 10px 0;
  flex-grow: 1;
}
.priceButton {
  min-width: 100%;
  font-size: 16px;
  width: 200px;
  padding: 12px;
  /* border: 2px solid rgba(255, 255, 255, 1); */
  border-radius: 18px;
  margin-top: 7% !important;
  transition: transform 0.2s ease;
  border: none;
}

.light .priceButton {
  margin: 10px;
  background-color: rgba(255, 99, 146, 0.6);
  color: rgba(255, 255, 255, 1);
  border: 2px solid rgba(255, 99, 146, 1);
}
.dark .priceButton {
  background-color: rgba(255, 99, 146, 0.6);
  color: rgba(255, 255, 255, 1);
  border: 2px solid rgba(255, 99, 146, 1);
  border-radius: 18px;
}

.priceButton:hover {
  /* box-shadow: 0px 4px 4px #0d47a1; */
  /* background-color: #1e88e5; */
  transform: scale(1.01);
  /* text-shadow: 0px 4px 4px #0d47a1; */
  /* background: #f3d9dc;
  color: #0d47a1; */
  cursor: pointer;
}
.dark .priceButton:hover {
  background-color: rgba(244, 193, 199, 1);
  color: rgba(3, 63, 93, 1);
}
.light .priceButton:hover {
  background-color: rgba(248, 46, 107, 0.6);
  color: #fff;
}

.pageService a {
  align-self: center;
  margin-top: 5%;
  width: 100%;
  margin-top: auto;
}
.en .serviceH2 {
  font-family: "Nanum Brush Script";
}
@media screen and (max-width: 1100px) {
  .ge .serviceH2 {
    font-size: 60px;
  }
}

@media (max-width: 768px) {
  .serviceCardsGrid {
    grid-template-columns: 1fr;
    gap: 40px;
  }

  .serviceCard {
    padding: 30px;
    font-size: 14px;
  }

  .serviceCard h2 {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .serviceCard:hover {
    transform: scale(1.05);
    transition: transform 0.8s ease;
  }
}

@media (max-width: 480px) {
  .serviceCard {
    padding: 20px;
    font-size: 13px;
  }

  .serviceCard h2 {
    font-size: 18px;
    margin-bottom: 15px;
  }

  .serviceCardsGrid {
    gap: 30px;
    padding: 2% 10%;
  }

  .serviceCard p {
    margin: 5px 0;
  }
  .ge .serviceH2 {
    font-size: 40px;
  }
}

@media screen and (max-width: 600px) {
  .pageService {
    padding: 2% 0%;
    gap: 0vh;
  }
  .pageService .image {
    height: auto;
  }
  .serviceCardsGrid {
    gap: 30px;
    padding: 2% 10%;
  }
}
